import { ApiClient } from "../helpers/api-client";

const apiClient = new ApiClient();

const kullanicilarListesi = async () => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/kullanicilar`,
  });
};

const kullanici = async (id) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/kullanici/${id}`,
  });
};

const kullaniciKayit = async (ad, soyad, gsm, mail, parola) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/kullanici_kayit`,
    json: {
      ad: ad,
      soyad: soyad,
      gsm: gsm,
      mail: mail,
      parola: parola,
    },
  });
};

const kullaniciGuncelle = async (ad, soyad, gsm, mail, parola, id) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/kullanici_guncelle/${id}`,
    json: {
      ad: ad,
      soyad: soyad,
      gsm: gsm,
      mail: mail,
      parola: parola,
    },
  });
};

export { kullanicilarListesi, kullanici, kullaniciKayit, kullaniciGuncelle };
