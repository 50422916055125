<template>
  <div class="text-center">
    <v-dialog persistent v-model="modal" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ cardText }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="kullanici_bilgisi.ad"
            name="ad"
            id="ad"
            label="Ad"
          ></v-text-field>
          <v-text-field
            v-model="kullanici_bilgisi.soyad"
            name="soyad"
            id="soyad"
            label="Soyad"
          ></v-text-field>
          <v-text-field
            v-model="kullanici_bilgisi.gsm"
            name="gsm"
            id="gsm"
            label="gsm"
            v-mask="'0 (###) ### ## ##'"
          ></v-text-field>
          <v-text-field
            v-model="kullanici_bilgisi.mail"
            name="mail"
            id="mail"
            label="mail"
          ></v-text-field>
          <v-text-field
            v-model="parola"
            name="parola"
            label="Parola"
            hint="En az 6 karakter"
            counter
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="$emit('hideDialog')">Vazgeç</v-btn>
          <v-btn color="primary" @click="crud"> {{ buttonText }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  kullanici,
  kullaniciKayit,
  kullaniciGuncelle,
} from "@/query/kullanicilar";

export default {
  watch: {
    modal() {
      this.crudFunction(this.gelen_id);
    },
  },

  props: { modal: null, gelen_id: null },
  data() {
    return {
      kullanici_bilgisi: [],
      buttonText: "Kaydet",
      cardText: "Yeni Kullanici",
      crud: this.kaydet,
      show1: false,
      parola: null,
    };
  },

  methods: {
    async crudFunction(gelen_id) {
      if (this.gelen_id != null) {
        this.kullanici_bilgisi = await kullanici(gelen_id);
        this.buttonText = "Güncelle";
        this.cardText = "Bilgileri Güncelle";
        this.crud = this.guncelle;
      } else {
        this.kullanici_bilgisi = [];
        this.buttonText = "Kaydet";
        this.cardText = "Yeni Kullanici";
        this.crud = this.kaydet;
      }
    },
    async kaydet() {
      const sonuc = await kullaniciKayit(
        this.kullanici_bilgisi.ad,
        this.kullanici_bilgisi.soyad,
        this.kullanici_bilgisi.gsm,
        this.kullanici_bilgisi.mail,
        this.parola
      );
      if (sonuc != undefined) {
        this.$emit("hideDialog");
      }
    },
    async guncelle() {
      const sonuc = await kullaniciGuncelle(
        this.kullanici_bilgisi.ad,
        this.kullanici_bilgisi.soyad,
        this.kullanici_bilgisi.gsm,
        this.kullanici_bilgisi.mail,
        this.parola,
        this.gelen_id
      );
      if (sonuc != undefined) {
        this.$emit("hideDialog");
      }
    },
  },
};
</script>
