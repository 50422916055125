<template>
  <layout>
    <template slot="main">
      <v-card>
        <crud
          :modal="tetikle"
          :gelen_id="gelen_id"
          @hideDialog="showClose()"
        ></crud>
        <v-card-title>
          Görevler
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              tetikle = true;
              gelen_id = null;
            "
          >
            Yeni Kullanıcı
          </v-btn>
        </v-card-title>
        <datatables :headers="headers" :items="kullanicilar">
          <template v-slot:dt_actions="{ item }">
            <v-icon small class="mr-2" @click="updateDialog(item.id)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="silDialog(item)">mdi-delete</v-icon>
          </template>
        </datatables>
      </v-card>
    </template>
  </layout>
</template>

<script>
import Crud from "./Crud.vue";
import Datatables from "@/components/datatables/index.vue";
import { kullanicilarListesi } from "@/query/kullanicilar";
import Layout from "@/components/Layout";
export default {
  components: { Crud, Datatables, Layout },

  async mounted() {
    this.kullanicilar = await kullanicilarListesi();
  },

  methods: {
    showClose() {
      this.tetikle = false;
    },
    async silDialog(item) {
      const result = await this.$dialog.error({
        text: "Kayıt silinsin mi?",
        title: "Dikkat",
        actions: {
          true: {
            color: "error",
            text: "Evet",
          },
          false: "Hayır",
        },
      });
      if (result) {
        console.log(result);
        console.log(item);
        // const sonuc = await gorevSil(item.id);
        // if (sonuc.ok) {
        //   this.kullanicilar = await kullanicilarListesi();
        // }
      }
    },
    updateDialog(item) {
      this.gelen_id = item;
      this.tetikle = true;
    },
  },

  watch: {
    async tetikle(val) {
      if (val === false) {
        this.kullanicilar = await kullanicilarListesi();
      }
    },
  },

  data() {
    return {
      kullanicilar: [],
      tetikle: false,
      gelen_id: null,
      options: {},
      headers: [
        {
          text: "AdSoyad",
          value: "adsoyad",
          filterType: "string",
        },
        {
          text: "Gsm",
          value: "gsm",
          filterType: "string",
        },
        {
          text: "Mail",
          value: "mail",
          filterType: "string",
        },
        {
          text: "İŞLEMLER",
          value: "actions",
          hasSlot: true,
        },
      ],
    };
  },
};
</script>
